import axios from '@/utils/axios';

export function fundListQuery(data) {
  return axios({
    url: '/backend/fund/fundListQuery',
    method: 'post',
    data,
  });
}

export function fundDistribution(data) {
  return axios({
    url: '/backend/fund/fundDistribution',
    method: 'post',
    data,
  });
}

export function fundFileUpload(data) {
  return axios({
    url: '/backend/batch/upLoadAndLoanBatch',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'multipart/form-data'
    },
  });
}
